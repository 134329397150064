/* eslint-disable no-dupe-keys */
/* eslint-disable consistent-this */
/* eslint-disable no-unused-vars */
import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'

import Slide from '@material-ui/core/Slide'
import Fade from '@material-ui/core/Fade'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SendIcon from '@material-ui/icons/Send'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import Typography from '@material-ui/core/Typography'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Grid
} from '@material-ui/core'
import { LIGHTBLUE } from '../../utils/color_utils'
import {
  APP_TYPE,
  NEW_CORP_USERS,
  NEW_PUBLIC_USERS,
  GET_ALL_COMPANY_SETTINGS,
  GET_ALL_GENERAL_SETTINGS,
  SHOW_TOAST,
  HARMONY_USER_TYPES,
  IS_HARMONY,
} from '../../store/actions/types'
import { showToast } from '../../store/actions/toastAction'
import {
  getAllCompanySettings,
  getAllGeneralSettings,
} from '../../store/actions/companyAdminActions'
import { v3 } from 'uuid'
import {Checkbox} from '@material-ui/core'

const styles = theme => ({
  appBar: {
    position: 'static',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    backgroundColor: '#d51317'
  },
  flex: {
    flex: 1,
    width: 600
  },
  dialog: {},
  submitButton: {
    backgroundColor: '#2196F3 !important'
  },
  button: {
    'margin': theme.spacing.unit,
    'color': '#ffffff',
    'backgroundColor': '#0288d1',
    '&:hover': {
      backgroundColor: '#0288d1'
    }
  }
})

const Transition = props =>
  window.innerWidth >= 425 ? (
    <Fade checkIn={true} {...props} />
  ) : (
      <Slide direction='left' {...props} />
    )

class AddCorpUserDialog extends React.Component {
  state = {
    open: false,
    scroll: 'paper',
    usertype: '',
    surName: '',
    firstName: '',
    lastName: '',
    gender: '',
    position: '',
    points: '',
    race: '',
    nationality: '',
    idNumber: '',
    cellphone: '',
    department: '',
    operation: '',
    email: '',
    edit: false,
    uid: '',
    userID: '',
    demoOn: false
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  };

  handleCellChange = event => {
    const { name, value } = event.target;
  
    // If the field is 'cellphone', validate the phone number
   // if (name === 'cellphone') {
      if (this.validatePhoneNumber(value)) {
        console.log('handleCellChange: Valid phone number'); // You can display a success message or handle accordingly
         // Update the state
         this.setState({
          [name]: value,
          validPhone: true // Store the result of phone number validation
         });
      } else {
        console.log('handleCellChange: Invalid phone number'); // Show an error message or handle accordingly
        var params = {
          type: SHOW_TOAST,
          open: true,
          variant: "warning",
          message: "Invalid phone number!"
        };

        this.props.showToast(params);
          // Update the state
          this.setState({
            [name]: '',
            validPhone: false // Store the result of phone number validation
          });
        return;
      }
   // }
  
  };   

  validatePhoneNumber = (phoneNumber) => {
    // Regex for phone number validation (starts with '+' followed by 1-3 digit country code and 7-15 digit number)
    const phoneRegex = /^\+\d{1,3}\d{7,15}$/;
  
    // Test if the phone number matches the pattern
    if (phoneRegex.test(phoneNumber)) {
      return true; // Valid phone number
    } else {
      return false; // Invalid phone number
    }
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll })
  };

  handleChangeUser = event => {
    this.setState({ usertype: event.target.value })
  };

  handleClose = () => {
    this.setState({
      usertype: '',
      surName: '',
      firstName: '',
      lastName: '',
      gender: '',
      position: '',
      points: '',
      race: '',
      nationality: '',
      idNumber: '',
      cellphone: '',
      department: '',
      operation: '',
      email: '',
      edit: false,
      uid: '',
      userID: '',
      demoOn: false
    })
    // this.props.onOpenAddUserDialog()
  };

  closeDialog = () => {
    this.setState({
      usertype: '',
      surName: '',
      firstName: '',
      lastName: '',
      gender: '',
      position: '',
      points: '',
      race: '',
      nationality: '',
      idNumber: '',
      cellphone: '',
      department: '',
      operation: '',
      email: '',
      edit: false,
      uid: '',
      userID: '',
      demoOn: false
    })
    this.props.onOpenAddUserDialog()
  };

  renderUserTypes = () => {
    
    const userTypes = IS_HARMONY ? HARMONY_USER_TYPES : (APP_TYPE === 'PUBLIC' ? NEW_PUBLIC_USERS : NEW_CORP_USERS)

    return userTypes.map((v, i) => (
      <MenuItem key={v.id} value={v.id}>
        {v.value}
      </MenuItem>
    ))
  };

  filterZZZ = (comLable) => {
    let comLablex = comLable.toString()
    let myTruncatedString = comLablex.substring(0, 4)
    let finalstring = comLablex

    if (myTruncatedString === 'ZZZ_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    if (myTruncatedString === 'AAA_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    return finalstring
  };

  renderCompanySettings = (type) => {
    let itemdata = null
    console.log('renderCompanySettings: ', this.props.company_settings);

    if (this.props.company_settings && this.props.company_settings[type]) {
      itemdata = this.props.company_settings[type];
      itemdata.sort()
      return itemdata.map((v, i) => (
        <MenuItem key={i} value={v}>
          {this.filterZZZ(v)}
        </MenuItem>
      ))
    }
  };

  renderGeneralSettings = (type) => {
    let itemdata = null

    if (this.props.general_settings && this.props.general_settings[type]) {
      itemdata = this.props.general_settings[type];
      //itemdata.sort()
      return itemdata.map((v, i) => (
        <MenuItem key={i} value={v}>
          {v}
        </MenuItem>
      ))
    }
  };

  handleDemo = event => {
    this.setState({
      demoOn: event.target.checked
    })
  };

  // submitNewUser = () => {
  //   if (this.state.usertype === "") {
  //     var params = {
  //       type: SHOW_TOAST,
  //       open: true,
  //       variant: "warning",
  //       message: "Please select User Type!"
  //     };

  //     this.props.showToast(params);
  //     return;
  //   }

  //   if (this.state.surName === "") {
  //     var params = {
  //       type: SHOW_TOAST,
  //       open: true,
  //       variant: "warning",
  //       message: "Please enter Surname!"
  //     };

  //     this.props.showToast(params);
  //     return;
  //   }

  //   if (this.state.firstName === "") {
  //     var params = {
  //       type: SHOW_TOAST,
  //       open: true,
  //       variant: "warning",
  //       message: "Please enter Firstname!"
  //     };

  //     this.props.showToast(params);
  //     return;
  //   }

  //   if (this.state.email === "") {
  //     var params = {
  //       type: SHOW_TOAST,
  //       open: true,
  //       variant: "warning",
  //       message: "Please enter Email!"
  //     };

  //     this.props.showToast(params);
  //     return;
  //   }

  //   this.props.onSubmitUserClick({
  //     usertype: this.state.usertype,
  //     firstName: this.state.firstName,
  //     lastName: this.state.lastName,
  //     email: this.state.email,
  //     surName: this.state.surName,
  //     gender: this.state.gender,
  //     race: this.state.race,
  //     nationality: this.state.nationality,
  //     idNumber: this.state.idNumber,
  //     department: this.state.department,
  //     operation: this.state.operation,
  //   })

  //   // this.handleClose();
  // };

  componentDidMount() {
    console.log('AddCorpUserDialog cell: ', this.props.userdata.cellphone);

    //console.log('green editedUser : ', this.props.editedUser);
    // console.log("Edit user: ", this.props.edit)
    // console.log("Edit user: ", this.props.editedUser)
    // if (this.props.edit) {
    //   let editedUser = this.props.editedUser;
    //   this.setState({
    //     usertype: editedUser.userDescription ? editedUser.userDescription : '',
    //     surName: editedUser.surName ? editedUser.surName : '',
    //     firstName: editedUser.firstName ? editedUser.firstName : '',
    //     lastName: editedUser.lastName ? editedUser.lastName : '',
    //     gender: editedUser.gender ? editedUser.gender : '',
    //     race: editedUser.race ? editedUser.race : '',
    //     nationality: editedUser.nationality ? editedUser.nationality : '',
    //     idNumber: editedUser.idNumber ? editedUser.idNumber : '',
    //     department: editedUser.department ? editedUser.department : '',
    //     operation: editedUser.operation ? editedUser.operation : '',
    //     email: editedUser.email ? editedUser.email : ''
    //   })
    // }
    // getAllCompanySettings({
    //   companyID: this.props.userdata.companyID,
    // })
    // getAllGeneralSettings({
    //   companyID: this.props.userdata.companyID,
    // })
  }

  componentDidUpdate() {

    if ((this.props.edit === true) && (this.state.edit === false) && (this.props.open)) {
      let editedUser = this.props.editedUser;
      console.log('green editUser : ', editedUser);

      this.setState({
        usertype: editedUser.userType ? editedUser.userType : '',
        surName: editedUser.surName ? editedUser.surName : '',
        firstName: editedUser.firstName ? editedUser.firstName : '',
        lastName: editedUser.lastName ? editedUser.lastName : '',
        gender: editedUser.gender ? editedUser.gender : '',
        position: editedUser.position ? editedUser.position : '',
        points: editedUser.points ? editedUser.points : '',
        race: editedUser.race ? editedUser.race : '',
        nationality: editedUser.nationality ? editedUser.nationality : '',
        idNumber: editedUser.idNumber ? editedUser.idNumber : '',
       // cellphone: this.props.userdata.cellphone,//editedUser.cellphone ? editedUser.cellphone : '',
        cellphone: editedUser.cellphone ? editedUser.cellphone : '',
        department: editedUser.department ? editedUser.department : '',
        operation: editedUser.operation ? editedUser.operation : '',
        email: editedUser.email ? editedUser.email : '',
        edit: true,
        uid: editedUser.uid ? editedUser.uid : '',
        userID: editedUser.userID ? editedUser.userID : '',
        demoOn: editedUser.demoOn ? editedUser.demoOn : false
      })
    }
    else if ((this.props.edit === true) && (this.state.edit === true) && (this.props.open === false)) {
      let editedUser = this.props.editedUser;
      this.setState({
        usertype: '',
        surName: '',
        firstName: '',
        lastName: '',
        gender: '',
        position: '',
        points: '',
        race: '',
        nationality: '',
        idNumber: '',
        cellphone: '',
        department: '',
        operation: '',
        email: '',
        edit: false,
        uid: '',
        userID: '',
        demoOn: false
      })
    }
    // getAllCompanySettings({
    //   companyID: this.props.userdata.companyID,
    // })
    // getAllGeneralSettings({
    //   companyID: this.props.userdata.companyID,
    // })
  }


  render() {
    const { classes, theme, onSubmitUserClick, fullScreen } = this.props

    const {
      usertype,
      firstName,
      lastName,
      email
    } = this.state
   //console.log('user details props: ', this.props);

    let self = this
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          fullScreen={fullScreen}
          fullWidth
          maxWidth='sm'
          TransitionComponent={Transition}
          aria-labelledby='scroll-dialog-title'
        >
          <DialogTitle id='alert-dialog-title'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PersonAddIcon
                fontSize='small'
                style={{ color: `${LIGHTBLUE}` }}
              />
              <span
                style={{
                  color: `${LIGHTBLUE}`,
                  fontWeight: 'bold',
                  fontSize: '15px'
                }}
              >
                &nbsp;New User
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            <form autoComplete="off">
              <Grid container spacing={2}>
                {/* Row 1 */}
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='usertype'>Select User Type</InputLabel>
                    <Select
                      value={this.state.usertype}
                      onChange={this.handleChangeUser}
                      inputProps={{
                        name: 'usertype',
                        id: 'usertype'
                      }}
                    >
                      {this.renderUserTypes()}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Row 2 */}
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      label='Firstname'
                      fullWidth
                      value={this.state.firstName}
                      onChange={e => this.handleChange(e, 'firstName')}
                      inputProps={{
                        name: 'firstName',
                        id: 'firstName'
                      }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                  
                </Grid>
                {/* Row 3 */}
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <TextField
                      label='Lastname'
                      fullWidth
                      value={this.state.lastName}
                      onChange={e => this.handleChange(e, 'lastName')}
                      inputProps={{
                        name: 'lastName',
                        id: 'lastName'
                      }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                  {this.props.userdata.userType===14 ? null :
                  <FormControl fullWidth>
                    <TextField
                      required
                      label='Points'
                      fullWidth
                      value={this.state.points}
                      onChange={e => this.handleChange(e, 'points')}
                      inputProps={{
                        name: 'points',
                        id: 'points'
                      }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                 }
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <FormControl fullWidth>
                    <TextField
                      label='Cellphone Number'
                      fullWidth
                      value={this.state.cellphone}
                      onChange={e => this.handleChange(e, 'cellphone')}
                      inputProps={{
                        name: 'cellphone',
                        id: 'cellphone'
                      }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl> */}
                   <FormControl fullWidth>
                    <TextField
                      label='Cellphone Number'
                      fullWidth
                      value={this.state.cellphone}
                      onChange={e => this.handleChange(e, 'cellphone')}
                      onBlur={e => this.handleCellChange(e)} 
                      autoComplete="new-cellphone"
                       inputProps={{
                         name: 'cellphone',
                         id: 'cellphone'
                       }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      label='Initials'
                      fullWidth
                      value={this.state.surName}
                      onChange={e => this.handleChange(e, 'surName')}
                      inputProps={{
                        name: 'surName',
                        id: 'surName'
                      }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </Grid>
                {/* Row 4 */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      label='Email'
                      fullWidth
                      value={this.state.email}
                      onChange={e => this.handleChange(e, 'email')}
                      inputProps={{
                        name: 'email',
                        id: 'email'
                      }}
                      margin='normal'
                      // InputProps={{
                      //    readOnly: this.state.edit
                      //  }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      label='ID Number / Passport No'
                      fullWidth
                      value={this.state.idNumber}
                      onChange={e => this.handleChange(e, 'idNumber')}
                      inputProps={{
                        name: 'idNumber',
                        id: 'idNumber'
                      }}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </Grid>
                {/* Row 5 */}
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='gender'>Gender</InputLabel>
                    <Select
                      value={this.state.gender}
                      onChange={e => this.handleChange(e, 'gender')}
                      inputProps={{
                        name: 'gender',
                        id: 'gender'
                      }}
                    >
                      {this.renderGeneralSettings('gender')}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='position'>Position/Title</InputLabel>
                    <Select
                      value={this.state.position}
                      onChange={e => this.handleChange(e, 'position')}
                      inputProps={{
                        name: 'position',
                        id: 'position'
                      }}
                    >
                      {this.renderGeneralSettings('position')}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Row 6 */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='race'>Race</InputLabel>
                    <Select
                      value={this.state.race}
                      onChange={e => this.handleChange(e, 'race')}
                      inputProps={{
                        name: 'race',
                        id: 'race'
                      }}
                    >
                      {this.renderGeneralSettings('race')}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='country'>Country</InputLabel>
                    <Select
                      value={this.state.nationality}
                      onChange={e => this.handleChange(e, 'nationality')}
                      inputProps={{
                        name: 'nationality',
                        id: 'nationality'
                      }}
                    >
                      {this.renderGeneralSettings('countriesb')}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Row 7 */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='department'>Sector/Industry</InputLabel>
                    <Select
                      value={this.state.department}
                      onChange={e => this.handleChange(e, 'department')}
                      inputProps={{
                        name: 'department',
                        id: 'department'
                      }}
                    >
                      {this.renderCompanySettings('departments')}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                  <InputLabel htmlFor='demo'>Demo account</InputLabel>
                    <Checkbox
                      inputProps={{ 'aria-labelledby': 'select-all-users' }}
                       checked={this.state.demoOn ? true : false}
                      onChange={e => {
                        this.handleDemo(e)
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={e => onSubmitUserClick({
                usertype: this.state.usertype,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                surName: this.state.surName,
                gender: this.state.gender,
                position: this.state.position,
                points: this.state.points,
                race: this.state.race,
                nationality: this.state.nationality,
                idNumber: this.state.idNumber,
                cellphone: this.state.cellphone,
                department: this.state.department,
                operation: this.state.operation,
                edit: this.state.edit,
                uid: this.state.uid,
                userID: this.state.userID,
                feedbackOn: true,
                gfeedbackOn: true,
                feedback: 0,
                demoOn: this.state.demoOn
              })}
              className={classes.button}
              size='large'
            >
              <SendIcon />
              Submit
            </Button>
            <Button onClick={this.closeDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const AddCorpUserDlg = withMobileDialog({ breakpoint: 'xs' })(
  AddCorpUserDialog
)

const AddCorpUserDlgStyled = withStyles(styles, { withTheme: true })(AddCorpUserDlg)


const mapStateToProps = state => {
  return {
    company_settings: state.users.company_settings,
    general_settings: state.users.general_settings,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    getAllCompanySettings: params => dispatch(getAllCompanySettings(params)),
    getAllGeneralSettings: params => dispatch(getAllGeneralSettings(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCorpUserDlgStyled);
