import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { LIGHTBLUE, RED, LIGHTGREY } from "../../utils/color_utils";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import SendIcon from "@material-ui/icons/Send";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  TextField,
  Tab,
  Tabs,
  NoSsr,
  AppBar,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import { getAllCompanySettings, getAllGeneralSettings } from '../../store/actions/companyAdminActions'
import { VisibilityOff, Visibility } from "@material-ui/icons";
import {
  SHOW_TOAST,
  UPDATE_USER_SUCCESS,
  CLEAR_UPDATE_USER_SUCCESS,
  GET_ALL_COMPANY_SETTINGS,
  GET_ALL_GENERAL_SETTINGS
} from "../../store/actions/types";
import {
  updateUserProfile,
  updateUserPassword,
  showUserProfile
} from "../../store/actions/userDialogActions";
import { showToast, clearStates } from "../../store/actions/toastAction";
//import { getOpinions } from "../../store/actions/opinionActions";
import { bindActionCreators } from 'redux'
import {Checkbox} from '@material-ui/core'
import { withRouter } from "react-router-dom";


const tableM = {
  color: "#515A5A",
  fontSize: 15,
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2
}
const tableC = {
  color: "#ffffff",
  fontSize: 15,
  border: "0px",
  padding: 1,
  borderRadius: 2,
  fontweight: 'bold',
  backgroundColor: '#515A5A'
}
const tableMh = {
  color: "#515A5A",
  fontSize: 15,
  border: "2px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2
}
const tableS = {
  color: "#515A5A",
  fontSize: 10,
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 1,
  borderRadius: 2
}
const tableH = {
  color: "#515A5A",
  fontSize: 14,
  border: "0px",
  backgroundColor: '#F2F3F4',
  padding: 10,
  borderRadius: 2
}

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 10, minHeight: "90vh" }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

function LinkTab(props) {
  return (
    <Tab component="a" onClick={event => event.preventDefault()} {...props} />
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabsRoot: {
    backgroundColor: `${LIGHTBLUE}`
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
});

class UserTabs extends React.Component {
  state = {
    value: 0,
    firstName: "",
    lastName: "",
    cellphone: "",
    calfname: "",
    calfemail: "",
    calfpassword: "",
    showPassword: "",
    biography: "",
    email: "",
    userURL: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
    receivePLDPEmails: false,
    receiveEmails: false,
    unsavedchanges: false,
    userID: "",
    uid: "",
    department: "",
    position: "",
    nationality: "",
    self: 0,
    selfparticipants: 0,
    selfopinion: "",
    others: 0,
    othersopinion: "",
    othersparticipants: 0,
    organisation: 0,
    orgopinion: "",
    orgparticipants: 0,
    society: 0,
    sosopinion: "",
    sosparticipants: 0,
    feedbackOn: true,
    gfeedbackOn: true,
    demoOn: true
  };

  handleTabChange = (event, value) => {
    if (this.state.unsavedchanges) {
      var params = {
        type: "SHOW_TOAST",
        open: true,
        variant: "warning",
        message: "Please save current changes first."
      };

      this.props.showToast(params);
      return;
    }

    this.setState({ ...this.state, value });
  };

  handleChange = prop => event => {
    if (prop === "receiveEmails" || prop === "receivePLDPEmails") {
      this.setState({
        [prop]: JSON.parse(event.target.value),
        unsavedchanges: true
      });
    } else this.setState({ [prop]: event.target.value, unsavedchanges: true });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  handleClickConfirmPassword = () => {
    this.setState(state => ({
      showConfirmPassword: !state.showConfirmPassword
    }));
  };
  handlePasswordStrength(password) {
    var strengthMessage = "Strength: ";
    if (!password.length) return strengthMessage + "None";
    if (password.length < 8) return strengthMessage + "Weak";
    var hasUpperCase = /[A-Z]/.test(password);
    var hasLowerCase = /[a-z]/.test(password);
    var hasNumbers = /\d/.test(password);
    var hasNonalphas = /\W/.test(password);
    if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas > 3) {
      return strengthMessage + " Strong";
    } else {
      return strengthMessage + "Medium";
    }
  }

  updateUserProfile = () => {
    if (!this.state.firstName.trim()) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Firstname is required!"
      };

      this.props.showToast(params);
      return;
    }

    if (!this.state.lastName.trim()) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Lastname is required!"
      };

      this.props.showToast(params);
      return;
    }

    var body = {
      ...this.state
    };

    // Post CHANGES
    this.props.updateProfileChanges(body);
  };

  changeUserPassword = () => {
    if (!this.state.password.trim()) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Password is required!"
      };

      this.props.showToast(params);
      return;
    }

    if (!this.state.confirmPassword.trim()) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Confirm password is required!"
      };

      this.props.showToast(params);
      return;
    }

    if (this.state.password.trim() !== this.state.confirmPassword.trim()) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "warning",
        message: "Password and confirm password do not match!"
      };

      this.props.showToast(params);
      return;
    }

    var body = {
      ...this.state
    };

    // Post CHANGES
    this.props.updateUserPassword(body);
  };

  cancelUserChanges = () => {
    this.setState({
      ...this.state,
      firstName: this.props.userdetails[0].firstName || "",
      lastName: this.props.userdetails[0].lastName || "",
      cellphone: this.props.userdetails[0].cellphone || "",
      department: this.props.userdetails[0].department || "",
      position: this.props.userdetails[0].position || "",
      nationality: this.props.userdetails[0].nationality || "",
      calfemail: this.props.userdetails[0].calfemail || "",
      calfpassword: this.props.userdetails[0].calfpassword || "",
      biography: this.props.userdetails[0].biography || "",
      receivePLDPEmails: this.props.userdetails[0].receivePLDPEmails || false,
      receiveEmails: this.props.userdetails[0].receiveEmails || false,
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
      unsavedchanges: false
    });

    this.props.history.goBack();
  };

  componentDidUpdate = () => {
    var self = this;

    if (this.props.UPDATE_USER === UPDATE_USER_SUCCESS) {
      var params = {
        type: SHOW_TOAST,
        open: true,
        variant: "success",
        message: "User profile updated successfully."
      };

      this.setState({
        ...this.state,
        unsavedchanges: false
      });

      // show success toast
      this.props.showToast(params);

      // clear success state
      this.props.clearStates({ type: CLEAR_UPDATE_USER_SUCCESS });

      // Reload states
      this.props.showUserProfile({ type: "SHOW_USER_PROFILE_DIALOG" });
    }
  };

    // phandleChange = prop => event => {
    //   this.setState({ [prop]: event.target.value });
    // };

    phandleMouseDownPassword = event => {
      event.preventDefault();
    };

    phandleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };       


  componentDidMount = () => {
    this.setState({
      ...this.state,
      value: 0,
      companyID: this.props.userdetails[0].companyID,
      firstName: this.props.userdetails[0].firstName || "",
      lastName: this.props.userdetails[0].lastName || "",
      cellphone: this.props.userdetails[0].cellphone || "",
      calfname: this.props.userdetails[0].calfname || "",
      department: this.props.userdetails[0].department || "",
      position: this.props.userdetails[0].position || "",
      nationality: this.props.userdetails[0].nationality || "",
      calfemail: this.props.userdetails[0].calfemail || "",
      calfpassword: this.props.userdetails[0].calfpassword || "",
      biography: this.props.userdetails[0].biography || "",
      email: this.props.userdetails[0].email || "",
      userURL: this.props.userdetails[0].userURL || "",
      receivePLDPEmails: this.props.userdetails[0].receivePLDPEmails || true,
      receiveEmails: this.props.userdetails[0].receiveEmails || true,
      userID: this.props.userdetails[0].userID,
      uid: this.props.userdetails[0].id,
      feedbackOn: this.props.userdetails[0].feedbackOn ? true : false,
      gfeedbackOn: this.props.userdetails[0].gfeedbackOn ? true : false,
      demoOn: this.props.userdetails[0].demoOn ? true : false
    });

    this.props.getAllCompanySettings({
      companyID: this.props.userdata.companyID
    })
    this.props.getAllGeneralSettings({
      companyID: this.props.userdata.companyID
    })
    // this.props.getOpinions({
    //   leaderID: this.props.userdetails[0].userID
    // })
    this.putOpinions();
  };

  filterZZZ = (comLable) => {
    let comLablex = comLable.toString()
    let myTruncatedString = comLablex.substring(0, 4)
    let finalstring = comLablex

    if (myTruncatedString === 'ZZZ_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    if (myTruncatedString === 'AAA_') {
      finalstring = comLablex.substring(4, comLablex.length)
    }
    return finalstring
  };

  renderCompanySettings = (type) => {
    let itemdata = null
    console.log('renderCompanySettings: ', this.props.company_settings);

    if (this.props.company_settings && this.props.company_settings[type]) {
      itemdata = this.props.company_settings[type];
      itemdata.sort()
      return itemdata.map((v, i) => (
        <MenuItem key={i} value={v}>
          {this.filterZZZ(v)}
        </MenuItem>
      ))
    }
  };
  renderGeneralSettings = (type) => {
    let itemdata = null

    if (this.props.general_settings && this.props.general_settings[type]) {
      itemdata = this.props.general_settings[type];
     // itemdata.sort()
      return itemdata.map((v, i) => (
        <MenuItem key={i} value={v}>
          {v}
        </MenuItem>
      ))
    }
  };

  handleChangeIndustry = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  };
  handleChangePosition = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  };
  handleChangeCountry = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  };

  handleCellChange = event => {
    const { name, value } = event.target;
  
    // If the field is 'cellphone', validate the phone number
   // if (name === 'cellphone') {
      if (this.validatePhoneNumber(value)) {
        console.log('handleCellChange: Valid phone number'); // You can display a success message or handle accordingly
         // Update the state
         this.setState({
          [name]: value,
          validPhone: true // Store the result of phone number validation
         });
      } else {
        console.log('handleCellChange: Invalid phone number'); // Show an error message or handle accordingly
        var params = {
          type: SHOW_TOAST,
          open: true,
          variant: "warning",
          message: "Invalid phone number!"
        };

        this.props.showToast(params);
          // Update the state
          this.setState({
            [name]: '',
            validPhone: false // Store the result of phone number validation
          });
        return;
      }
   // }
  
  };   

  validatePhoneNumber = (phoneNumber) => {
    // Regex for phone number validation (starts with '+' followed by 1-3 digit country code and 7-15 digit number)
    const phoneRegex = /^\+\d{1,3}\d{7,15}$/;
  
    // Test if the phone number matches the pattern
    if (phoneRegex.test(phoneNumber)) {
      return true; // Valid phone number
    } else {
      return false; // Invalid phone number
    }
  };

  putOpinions = () => {
    let { opinions } = this.props
    console.log('inside putOpinions');

    var self_count = 0;
    var others_count = 0;
    var organisation_count = 0;
    var society_count = 0;

    var self_all_count = 0;
    var others_all_count = 0;
    var org_all_count = 0;
    var sos_all_count = 0;
    
    if (opinions) { Object.keys(opinions).map((opinionsID, index) => {
      console.log('inside putOpinions 2', opinions[index]);

      if (opinions[index].self) { 
        self_all_count = self_all_count + 1;
        self_count = self_count + opinions[index].self 
      }
      if (opinions[index].others) { 
        others_all_count = others_all_count + 1;
        others_count = others_count + opinions[index].others 
      }
      if (opinions[index].organisation) { 
        org_all_count = org_all_count + 1;
        organisation_count = organisation_count + opinions[index].organisation 
      }   
      if (opinions[index].society) { 
        sos_all_count = sos_all_count + 1;
        society_count = society_count + opinions[index].society 
      }   
    })}

    if (opinions) {
     this.setState({
      self: parseInt((self_count / self_all_count)),
      others: parseInt((others_count / others_all_count)),
      organisation: parseInt((organisation_count / org_all_count)),
      society: parseInt((society_count / sos_all_count)),
      selfparticipants: self_all_count,
      othersparticipants: others_all_count,
      orgparticipants: org_all_count,
      sosparticipants: org_all_count,
     })};
  }

   getOpinion = (props) => {
    for (let x in props) {
      return props[x].self
    }
   }

   handleFeedback = event => {
    this.setState({
      feedbackOn: event.target.checked
    })
  };

   handleFeedbackG = event => {
    this.setState({
      gfeedbackOn: event.target.checked
    })
  };

  handleDemo = event => {
    this.setState({
      demoOn: event.target.checked
    })
  };

  render() {
    const { classes, opinions } = this.props;
    const { value } = this.state;

    return (
      <NoSsr>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={this.handleTabChange}
              classes={{
                root: classes.tabsRoot
              }}
            >
              <LinkTab label="Profile Settings" href="page1" />
              <LinkTab label="Email Notifications" href="page2" />
              <LinkTab label="Edit Password" href="page3" />
              <LinkTab label="Feedback" href="page4" />
            </Tabs>
          </AppBar>
          {value === 0 && (
            <TabContainer>
              <div>
                <Grid container spacing={8}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="firstName"
                      label="First name"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      aria-describedby="First name"
                      value={this.state.firstName}
                      onChange={event => {
                        const { value } = event.target;
                        this.setState({
                          firstName: value,
                          unsavedchanges: true
                        });
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="lastName"
                      label="Last name"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      aria-describedby="lastName"
                      value={this.state.lastName}
                      onChange={event => {
                        const { value } = event.target;
                        this.setState({
                          lastName: value,
                          unsavedchanges: true
                        });
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="biography"
                      aria-describedby="biography-content"
                      label="Biography"
                      fullWidth
                      multiline
                      rows="3"
                      margin="normal"
                      value={this.state.biography}
                      onChange={event => {
                        const { value } = event.target;
                        this.setState({
                          biography: value
                        });
                      }}
                    />
                  </Grid>
                  {/* <Grid
                    style={{
                      backgroundColor: `${LIGHTGREY}`,
                      padding: "0px 6px"
                    }}
                    item
                    xs={12}
                    md={6}
                  > */}
                 <Grid item xs={12} md={6}>
                    <TextField
                      InputProps={{
                        readOnly: true
                      }}
                      id="email"
                      aria-describedby="email-content"
                      label="Email"
                      fullWidth
                      margin="normal"
                      value={this.state.email}
                      onChange={event => {
                        const { value } = event.target;
                        this.setState({
                          email: value
                        });
                      }}
                      helperText="readonly"
                    />
                  </Grid>

                     <Grid item xs={12} md={6}>
                    <TextField
                      InputProps={{
                        readOnly: true
                      }}
                      id="userURL"
                      aria-describedby="userURL-content"
                      label="Personalized invite link"
                      fullWidth
                      margin="normal"
                      // value={this.state.userURL}
                      value={"harmony.thinklead.co.za/?id=" + this.props.userdata.companyID}
                      onChange={event => {
                        const { value } = event.target;
                        this.setState({
                          userURL: value
                        });
                      }}
                      helperText="readonly"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {/* <TextField
                      autoFocus
                      id="industry"
                      label="Industry"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      aria-describedby="Industry"
                      value={this.state.industry}
                      onChange={event => {
                        const { value } = event.target;
                        this.setState({
                          industry: value,
                          unsavedchanges: true
                        });
                      }}
                      fullWidth
                      required
                    /> */}
                  <FormControl fullWidth>
                    <InputLabel htmlFor='department'>Sector</InputLabel>
                    <Select
                      value={this.state.department}
                      onChange={e => this.handleChangeIndustry(e, 'department')}
                      inputProps={{
                        name: 'department',
                        id: 'department'
                      }}
                    >
                      {this.renderCompanySettings('departments')}
                    </Select>
                  </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} md={6}>
                    <TextField
                      autoFocus
                      id="calfemail"
                      label="Executive Club Email"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      aria-describedby="calfemail"
                      value={this.state.calfemail}
                      onChange={event => {
                        const { value } = event.target;
                        this.setState({
                          calfemail: value,
                          unsavedchanges: true
                        });
                      }}
                      fullWidth
                      required
                    />
                  </Grid> */}

                  {/* <Grid item xs={12} md={6}>
                  <TextField
                      id="calfpassword"
                      placeholder="Calf password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.calfpassword}
                      label="Calf Password"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      aria-describedby="calfpassword"
                      // onChange={this.phandleChange('calfpassword')}
                      onChange={event => {
                        const { value } = event.target;
                        this.setState({
                          calfpassword: value,
                          unsavedchanges: true
                        });
                      }}
                      InputProps={{
                        endAdornment:(
                       <InputAdornment position="end" >
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.phandleClickShowPassword}
                          onMouseDown={this.phandleMouseDownPassword}
                        >
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                         </InputAdornment>
                        )
                      }}
                    /> */}

                    {/* <TextField
                      id="calfpassword"
                      label="Executive Club Password"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      aria-describedby="calfpassword"
                      value={this.state.calfpassword}
                      onChange={event => {
                        const { value } = event.target;
                        this.setState({
                          calfpassword: value,
                          unsavedchanges: true
                        });
                      }}
                      fullWidth
                      required
                    /> 
                  </Grid>  */}  
                  <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='position'>Position/Title</InputLabel>
                    <Select
                      value={this.state.position}
                      onChange={e => this.handleChangePosition(e, 'position')}
                      inputProps={{
                        name: 'position',
                        id: 'position'
                      }}
                    >
                      {this.renderGeneralSettings('position')}
                    </Select>
                  </FormControl>
                </Grid> 
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='country'>Country</InputLabel>
                    <Select
                      value={this.state.nationality}
                      onChange={e => this.handleChangeCountry(e, 'nationality')}
                      inputProps={{
                        name: 'nationality',
                        id: 'nationality'
                      }}
                    >
                      {this.renderGeneralSettings('countriesb')}
                    </Select>
                  </FormControl>
                </Grid>  
                <Grid item xs={12} md={6}>
                <TextField
                      id="cellphone"
                      label="cellphone"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      aria-describedby="cellphone"
                      value={this.state.cellphone}
                      onChange={event => {
                        const { value } = event.target;
                        this.setState({
                          cellphone: value,
                        });
                      }}
                      onBlur={e => this.handleCellChange(e)} 
                      autoComplete="new-cellphone"
                      inputProps={{
                        name: 'cellphone',
                        id: 'cellphone'
                      }}
                      fullWidth
                      required
                    />
                  </Grid> 
                <Grid item xs={12} md={6}>
                  {/* <FormControl fullWidth> */}
                    <InputLabel htmlFor='feedbackOn'>Feedback Display</InputLabel>
                    <Checkbox
                      inputProps={{ 'aria-labelledby': 'select-all-users' }}
                      checked={this.state.feedbackOn ? true : false}
                      //checked={this.props.userdetails[0].feedbackOn ? true : false}
                      onChange={e => {
                        this.handleFeedback(e)
                      }}
                    />
                  {/* </FormControl> */}
                  {/* <FormControl fullWidth> */}
                    <InputLabel htmlFor='gfeedbackOn'>Global Feedback Display</InputLabel>
                    <Checkbox
                      inputProps={{ 'aria-labelledby': 'select-all-users' }}
                       checked={this.state.gfeedbackOn ? true : false}
                      //checked={this.props.userdetails[0].gfeedbackOn ? true : false}
                      onChange={e => {
                        this.handleFeedbackG(e)
                      }}
                    />
                 <InputLabel htmlFor='demo'>Demo Account</InputLabel>
                    <Checkbox
                      inputProps={{ 'aria-labelledby': 'select-all-users' }}
                       checked={this.state.demoOn ? true : false}
                      //checked={this.props.userdetails[0].gfeedbackOn ? true : false}
                      onChange={e => {
                        this.handleDemo(e)
                      }}
                    />
                  {/* </FormControl>                   */}
                </Grid>            
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: RED, color: "#fff" }}
                      onClick={this.cancelUserChanges.bind(this)}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.updateUserProfile.bind(this)}
                      style={sendBtn}
                    >
                      <SendIcon className={classes.rightIcon} />
                      &nbsp; UPDATE PROFILE
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </TabContainer>
          )}
          {value === 1 && (
            <TabContainer>
              <div>
                <Grid container spacing={8}>
                  <Grid item xs={12}>
                    <FormGroup row>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                        fullWidth
                      >
                        <FormLabel
                          component="small"
                          className={classes.subscribeHeading}
                        >
                          Receive email notifications for new posts?
                        </FormLabel>
                        <RadioGroup
                          aria-label="subscribePosts"
                          name="receiveEmails"
                          className={classes.group}
                          value={String(this.state.receiveEmails)}
                          onChange={this.handleChange("receiveEmails").bind(
                            this
                          )}
                        >
                          <FormControlLabel
                            value={"true"}
                            control={<Radio />}
                            label="Subscribe"
                          />
                          <FormControlLabel
                            value={"false"}
                            control={<Radio />}
                            label="Unsubscribe"
                          />
                        </RadioGroup>
                      </FormControl>
                    </FormGroup>
                    <FormGroup row>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                        fullWidth
                      >
                        <FormLabel
                          component="small"
                          className={classes.subscribeHeading}
                        >
                          Receive email notifications for Personal Development Plan?
                        </FormLabel>
                        <RadioGroup
                          aria-label="subscribePosts"
                          name="receivePLDPEmails"
                          className={classes.group}
                          value={String(this.state.receivePLDPEmails)}
                          onChange={this.handleChange("receivePLDPEmails").bind(
                            this
                          )}
                        >
                          <FormControlLabel
                            value={"true"}
                            control={<Radio />}
                            label="Subscribe"
                          />
                          <FormControlLabel
                            value={"false"}
                            control={<Radio />}
                            label="Unsubscribe"
                          />
                        </RadioGroup>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: RED, color: "#fff" }}
                      onClick={this.cancelUserChanges.bind(this)}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="contained"
                      style={sendBtn}
                      onClick={this.updateUserProfile.bind(this)}
                    >
                      <SendIcon className={classes.rightIcon} />
                      &nbsp;UPDATE SUBSCRIPTION
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </TabContainer>
          )}
          {value === 2 && (
            <TabContainer>
              <div>
                <Grid container spacing={8}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="password"
                      type={this.state.showPassword ? "text" : "password"}
                      label="New password"
                      value={this.state.password}
                      helperText={this.handlePasswordStrength(
                        this.state.password
                      )}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={this.handleChange("password")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={this.handleClickShowPassword}
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="confirmpassword"
                      type={
                        this.state.showConfirmPassword ? "text" : "password"
                      }
                      fullWidth
                      label="Confirm password"
                      value={this.state.confirmPassword}
                      helperText={this.handlePasswordStrength(
                        this.state.confirmPassword
                      )}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={this.handleChange("confirmPassword")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={this.handleClickConfirmPassword}
                            >
                              {this.state.showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: RED, color: "#fff" }}
                      onClick={this.cancelUserChanges.bind(this)}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="contained"
                      style={sendBtn}
                      onClick={this.changeUserPassword.bind(this)}
                    >
                      <SendIcon className={classes.rightIcon} />
                      &nbsp;UPDATE PASSWORD
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </TabContainer>
          )}
         {value === 3 && (
            <TabContainer>
              <div><b>Opinion Polls</b></div>
              <div>
              <MDBContainer>  
                    <MDBRow> 
                      <MDBCol style={tableC} >
                      <b> Opinion</b>
                      </MDBCol>          
                      <MDBCol style={tableC} >
                      <b> Mean Score</b>
                      </MDBCol> 
                      <MDBCol style={tableC} >
                      <b> Participants</b>
                      </MDBCol> 
                    </MDBRow>  
                    <MDBRow> 
                      <MDBCol style={tableM} >
                      <b>Leading Self:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.self}
                      </MDBCol>   
                      <MDBCol style={tableMh} >
                       { this.state.selfparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Feedback on Leading Self: </i></b>
                    :null}
                    </MDBRow>                     
                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].selfopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol >
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow> 
                      </React.Fragment>    
                     ) 
                   })} 

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> Leading Others:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.others}
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       { this.state.othersparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Feedback on Leading Others</i></b>
                    :null}
                    </MDBRow>                     
                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].othersopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     ) 
                   })}

                   <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> Leading Organisation:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.organisation}
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       { this.state.orgparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Feedback on Leading Organisation</i></b>
                    :null}
                    </MDBRow>                     
                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].orgopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     ) 
                   })}

                    <MDBRow> 
                      <MDBCol style={tableMh} >
                     <b> Leading Society:</b>
                      </MDBCol> 
                      <MDBCol style={tableM} >
                       { this.state.society}
                      </MDBCol>   
                      <MDBCol style={tableM} >
                       { this.state.sosparticipants}
                      </MDBCol>                                          
                    </MDBRow>  
                    <MDBRow>
                    {opinions && Object.keys(opinions).length > 0 ?     
                     <b><i>List of Feedback on leading Society</i></b>
                    :null}
                    </MDBRow>                     
                   {opinions && Object.keys(opinions).map((opinionsID, index) => {
                     return ( 
                     <React.Fragment>  
                      <MDBRow>
                       <MDBCol style={tableH}>
                      { opinions[index].sosopinion}
                      </MDBCol>
                      </MDBRow>    
                       <MDBRow>
                       <MDBCol>
                         &nbsp;&nbsp;
                      </MDBCol>
                      </MDBRow>                             
                     </React.Fragment>    
                     ) 
                   })}

               </MDBContainer>                 
              </div>
            </TabContainer>
          )}
        </div>
      </NoSsr>
    );
  }
}

const sendBtn = {
  backgroundColor: LIGHTBLUE,
  color: "#fff",
  margin: "8px"
};

UserTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

const userTabsStyled = withStyles(styles)(UserTabs);

const mapStateToProps = state => {
  return {
    userdata: state.userDialog.userDetails[0],
    UPDATE_USER: state.userDialog.UPDATE_USER,
    company_settings: state.users.company_settings,
    general_settings: state.users.general_settings,
    opinions: state.opinions.opinion
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showToast: params => dispatch(showToast(params)),
    clearStates: params => dispatch(clearStates(params)),
    updateProfileChanges: params => dispatch(updateUserProfile(params)),
    updateUserPassword: params => dispatch(updateUserPassword(params)),
    showUserProfile: params => dispatch(showUserProfile(params)),
    getAllCompanySettings: params => dispatch(getAllCompanySettings(params)),
    getAllGeneralSettings: params => dispatch(getAllGeneralSettings(params)),
    //getOpinions: params => dispatch(getOpinions(params)),
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({
//     showToast,
//     clearStates,
//     updateProfileChanges,
//     updateUserPassword,
//     showUserProfile,
//     getAllCompanySettings
//   }, dispatch)
// }

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(userTabsStyled)
);
